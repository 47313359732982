var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"emailkits"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),_c('v-row',[_c('v-col',[_c('v-card',{},[_c('v-card-title',[_vm._v("Emailkits")]),_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Naam")]),_c('th',{staticClass:"text-center"},[_vm._v("Totaal verstuurd")]),_c('th',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v("Totaal opens (%)")]),_c('th',{staticClass:"text-center"},[_vm._v("Laatste e-mail")]),_c('th',{staticClass:"text-center"},[_vm._v("Status")])])]),(_vm.selected_customer_info && _vm.emailkits !== null)?_c('tbody',{staticClass:"router-links"},[_vm._l((_vm.emailkits),function(emailkit){return _c('router-link',{key:emailkit.id,attrs:{"to":{
                  name: 'emailkit-stats',
                  params: {
                    customerId: _vm.last_selected_customer_id,
                    emailkitId: emailkit.id,
                  },
                },"tag":"tr"}},[_c('td',{directives:[{name:"ripple",rawName:"v-ripple"}]},[_vm._v(_vm._s(emailkit.id))]),_c('td',{directives:[{name:"ripple",rawName:"v-ripple"}]},[_vm._v(_vm._s(emailkit.name))]),_c('td',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatCount")(emailkit.sent_cnt))+" ")]),_c('td',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatCount")(emailkit.open_cnt))+" ")]),_c('td',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatPercentageOf")(emailkit.open_cnt,emailkit.sent_cnt))+" ")]),_c('td',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"text-right"},[_vm._v(_vm._s(emailkit.last_sent))]),_c('td',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"text-right"},[(emailkit.status == 'active')?_c('v-chip',{attrs:{"color":"success","small":true,"label":true}},[_vm._v("Actief")]):_c('v-chip',{attrs:{"color":"red","small":true,"label":true}},[_vm._v("Uitgeschakeld")])],1)])}),(
                  _vm.selected_customer_info &&
                  _vm.emailkits !== null &&
                  _vm.emailkits.length == 0
                )?_c('tr',[_c('td',{attrs:{"colspan":"7"}},[_vm._v("Geen emailkits gevonden")])]):_vm._e()],2):_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"7"}},[_c('v-progress-linear',{attrs:{"active":true,"indeterminate":true,"color":"light-blue"}}),_vm._v("Bezig met laden... ")],1)])])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }