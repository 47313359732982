<template>
  <div class="emailkits">
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <v-row>
        <v-col>
          <v-card class="">
            <v-card-title>Emailkits</v-card-title>
            <v-simple-table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Naam</th>
                  <th class="text-center">Totaal verstuurd</th>
                  <th colspan="2" class="text-center">Totaal opens (%)</th>
                  <th class="text-center">Laatste e-mail</th>
                  <th class="text-center">Status</th>
                </tr>
              </thead>
              <tbody
                v-if="selected_customer_info && emailkits !== null"
                class="router-links"
              >
                <router-link
                  v-for="emailkit in emailkits"
                  :key="emailkit.id"
                  :to="{
                    name: 'emailkit-stats',
                    params: {
                      customerId: last_selected_customer_id,
                      emailkitId: emailkit.id,
                    },
                  }"
                  tag="tr"
                >
                  <td v-ripple>{{ emailkit.id }}</td>
                  <td v-ripple>{{ emailkit.name }}</td>
                  <td v-ripple class="text-right">
                    {{ emailkit.sent_cnt | formatCount }}
                  </td>
                  <td v-ripple class="text-right">
                    {{ emailkit.open_cnt | formatCount }}
                  </td>
                  <td v-ripple class="text-right">
                    {{
                      emailkit.open_cnt | formatPercentageOf(emailkit.sent_cnt)
                    }}
                  </td>
                  <td v-ripple class="text-right">{{ emailkit.last_sent }}</td>
                  <td v-ripple class="text-right">
                    <v-chip
                      v-if="emailkit.status == 'active'"
                      color="success"
                      :small="true"
                      :label="true"
                      >Actief</v-chip
                    >
                    <v-chip v-else color="red" :small="true" :label="true"
                      >Uitgeschakeld</v-chip
                    >
                  </td>
                </router-link>
                <tr
                  v-if="
                    selected_customer_info &&
                    emailkits !== null &&
                    emailkits.length == 0
                  "
                >
                  <td colspan="7">Geen emailkits gevonden</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="7">
                    <v-progress-linear
                      :active="true"
                      :indeterminate="true"
                      color="light-blue"
                    ></v-progress-linear
                    >Bezig met laden...
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CustomerPage from "@/mixins/customerpage";

export default {
  name: "Emailkits",
  mixins: [CustomerPage],
  data() {
    return {
      emailkits: null,
    };
  },
  computed: {
    breadcrumbs() {
      let b = this.defaultBreadcrumbs.slice();

      b.push({
        disabled: true,
        text: "Emailkits",
      });

      return b;
    },
  },
  mounted() {
    // load exports
    this.axios
      .get("customer/" + this.customerId + "/emailkits")
      .then((res) => {
        this.emailkits = res.data.emailkits;
      })
      .catch((error) => {
        if (error.response && error.response.status == 404) {
          // sponsor not found
          this.$router.push({
            name: "customer",
            params: {
              customerId: this.customerId,
            },
          });
        }
      });
  },
};
</script>
